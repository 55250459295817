<template>
      <div class="amenities">
            <span class="abs-title ml-10">Amenities</span>
            <!-- <Carousel v-if="!isLoading" :items="items" customPaging :scroller="false" :settings="settings"  /> -->
            <carousel v-if="!isLoading" :autoplay="false" :nav="false" :items="2.4" :margin="130" :center="true" :dotData="true" :dotsData="true">
                  <div class="item" v-for="(item, index) in items" :key="index" :data-dot="'<button class='+ 'carousel-dots' +'>' + (Number(index + 1)) + '</button>'">
                        <h4 class="carousel-title">
                              <span class="title">{{ item.title }}</span>
                              <span class="empty-title">&nbsp;</span>
                        </h4>
                        <img :src="item.image" width="100%" height="100%">
                  </div>
            </carousel>
      </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

//Services
import { getAmenities } from '@/services/axios/amenities.service';

export default {
      name: "amenities-page",
      components: { carousel },

      data(){
            return {
                  settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: "50px",
                        dots: true,
                        infinite: false,
                        speed: 500,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        initialSlide: 0,
                        responsive: [
                              {
                                    breakpoint: 900,
                                    settings: {
                                          centerPadding: "100px",
                                          slidesToShow: 2,
                                          slidesToScroll: 2,
                                    },
                              },
                              {
                                    breakpoint: 600,
                                    settings: {
                                          centerPadding: "50px",
                                          slidesToShow: 2,
                                          slidesToScroll: 2,
                                    },
                              },
                              {
                                    breakpoint: 480,
                                    settings: {
                                          centerPadding: "30px",
                                          slidesToShow: 1,
                                          slidesToScroll: 1,
                                    },
                              },
                        ],
                  },
                  items: []
            }
      },
      computed: {
            isLoading(){
                  return this.$store.state.isLoading
            }
      },
      methods: {
            getAmenities,
            async initComponent(){
                  const res = await this.getAmenities();
                  this.items = res.data.data;
            }
      },
      mounted(){
            this.initComponent();
            if((window.matchMedia("(max-width: 768px)").matches) || (window.matchMedia("(max-height: 820px)").matches)) {
                  document.querySelector('html').style.overflowY = 'hidden';
            }
      },
      beforeDestroy(){
            if((window.matchMedia("(max-width: 768px)").matches) || (window.matchMedia("(max-height: 820px)").matches)) {
                  document.querySelector('html').style.overflowY = 'scroll';
            }
      }
};
</script>

<style lang='scss'>
.amenities {
      border-top: 1px solid $mainBorderColor;
      padding-top: 120px;
      height: 86vh;

      @media screen and (max-width: 1366px){
            padding-top: 110px;
      }

      .slick-dots {
            position: relative;
            left: 18%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            color: $white;

            @media screen and (max-width: 1366px){
                  left: 13%;
            }

            @media screen and (max-width: 768px){
                  overflow: auto;
                  width: 65%;
                  margin: 0;
                  justify-content: flex-start;
            }

            li {
                  padding: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 18px;
                  font-family: $vaud-normal;

                  &:not(.slick-active){
                        opacity: .6;
                        // transform: scale(.7)
                  }
            }

            .slick-active {
                  color: $white;
                  transform: scale(.9);
                  border: 1px solid $white;
                  border-radius: 50%;
                  padding: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
            }

            .custom-dot {
                  font-size: 18px;
                  font-family: $vaud-normal;
            }
      }

      .abs-title {
            position: absolute;
            top: 70px;
            left: -1px;
            width: 572px;
            height: 159px;
            @include toRem(font-size, 100);
            color: $black;
            opacity: 0.1;
            font-family: $vaud-bold;
            
            @media screen and (max-width:768px) {
                  @include toRem(font-size, 60);
                  left: 0;
                  top: 14%;
            }
      }
}
div[dotdata="true"]{
      height: 100%;
}
.owl-carousel.owl-loaded{
      height: 100%;
      .owl-item{
            &.center.active{
                  transform: scale(1.3);
                  padding-top: 20px;
                  .carousel-title{
                        span{
                              &.title{
                                    display: block;
                              }
                              &.empty-title{
                                    display: none;
                              }
                        }
                  }
            }
      }
      .item{
            padding: 10px 0;
            width: 100%;
            .carousel-title{
                  color: #FFFFFF;
                  font-size: 16px;
                  text-align: center;
                  margin: 17px 0;
                  font-family: 'Vaud Semi Bold';
                  span{
                        &.title{
                              display: none;
                        }
                        &.empty-title{
                              display: block;
                        }
                  }
            }
      }
      .owl-dots{
            padding: 15px 30px 0 30px;
            text-align: right;
            position: absolute;
            right: 0;
            bottom: 0;
            .owl-dot{
                  cursor: pointer;
                  &.active{
                        .carousel-dots{
                              border: 1px solid #fff;
                              opacity: 1;
                        }
      
                  }
                  .carousel-dots{
                        border-radius: 50%;
                        color: #fff;
                        margin: 6px;
                        opacity: 0.5;
                        border: none;
                        background: none;
                        font-family: 'Vaud';
                        height: 35px;
                        width: 35px;
                        padding: 7px;
                  }
            }
            @media screen and (max-width:768px) {
                  text-align: center!important;
                  padding: 60px 10px;
            }
      }
}
@media screen and (min-width: 1200px){
      body{
            overflow: hidden;
      }
}

@media screen and (min-width: 1600px) {
      .owl-carousel.owl-loaded {
            .item {
                  .carousel-title{
                        margin: 30px 0 10px 0;
                  }
            }
      }
}
</style>